import request from "superagent";
import * as localforage from "localforage";

export interface IRequestParams {
	method: string;
	url: string;
	queryParameters?: { [key: string]: string | boolean | number | Date | undefined };
	body?: Object;
	accessToken?: string;
	basic?: string;
}

export abstract class ApiService {

    protected async executeRequestGoogle(params: IRequestParams) {
        const authorization = params.accessToken
            ? `Bearer ${params.accessToken}`
            : "";
        try {
            const req = this.createRequestGoogle(params, authorization);
            const response = await this.sendRequest(req);
            return response.body;
        } catch (error) {
            throw this.handleError(error);
        }
    }

	private handleError(error: any): any {
		if (error?.code) {
			return { ...error, code: Number(error.code) };
		}
		return error;
	}

	private async sendRequest(req: request.Request): Promise<Response> {
		return new Promise((resolve, reject) => {
			req.end((error: any, response: any) => {
				if (error || !response.ok) {
					reject(error || response.body);
				} else {
					resolve(response);
				}
			});
		});
	}

	private createRequestGoogle(
		params: IRequestParams,
		authorization: string,
	): request.Request {
		const req = request(params.method, params.url).set(
			"Accept",
			"application/json",
		);

		if (authorization) {
			req.set("Authorization", authorization);
		}

		return req;
	}

	protected async executeRequest<T>(params: IRequestParams) {
		let authData: any = await localforage.getItem("persist:root");
		 let authorization: string = "";

        if (authData) {
          const authObject: { auth: string } = JSON.parse(authData);
          const authDetails: { accessToken: string } = JSON.parse(authObject.auth);
          if (authDetails.accessToken) {
            authorization = "Bearer " + authDetails.accessToken;
          }
		  else if(localStorage.authToken){
			  authorization = "Bearer " + JSON.parse(localStorage.authToken);
		  }
        }

        if (authorization.length === 0 && params.accessToken) {
          authorization = "Bearer " + params.accessToken;
        }

		return new Promise<T>((resolve, reject) => {
			let req;
			if(params.basic){
				req = request(params.method, params.url)
				.set("Content-Type", "application/json");				
			}
			else{
				req = request(params.method, params.url)
				.set("Content-Type", "application/json");
			}

			const queryParameters = params.queryParameters;
			if (queryParameters) {
				req.query(queryParameters);
			}

			if (authorization) {
				req.set("Authorization", authorization);
			}

			if (params.body) {
				req.send(params.body);
			}

			req.end((error: any, response: any) => {
				if (error || !response.ok) {
					if (response && response.body) {
						reject(Object.assign({}, response.body, { code: Number(error.status) }));
						return;
					}
					reject(error);
				} else {
					resolve(response.body);
				}
			});
		});
	}
}

export class Api extends ApiService {
	baseApiUrl: string;

	constructor(url: string) {
		super();
		this.baseApiUrl = url;
	}

	public async get(url: string, data: any, query: any, accessToken?: string) {
		const requestParams: IRequestParams = {
			method: "GET",
			url: `${this.baseApiUrl}/${url}`,
			body: data,
			queryParameters: query,
            accessToken: accessToken
		};
		return this.executeRequest(requestParams);
	}

	public async getGoogle(
		url: string,
		data: any,
		query: any,
		accessToken?: string,
	) {
		const requestParams: IRequestParams = {
			method: "GET",
			url: `${url}`,
			body: data,
			queryParameters: query,
			accessToken: accessToken,
		};
		return this.executeRequestGoogle(requestParams);
	}

	public async post(url: string, data: any, query: any) {
		const requestParams: IRequestParams = {
			method: "POST",
			url: `${this.baseApiUrl}/${url}`,
			body: data,
			queryParameters: query
		};
		return this.executeRequest(requestParams);
	}

	public async put(url: string, data: any, query: any) {
		const requestParams: IRequestParams = {
			method: "PUT",
			url: `${this.baseApiUrl}/${url}`,
			body: data,
			queryParameters: query
		};
		return this.executeRequest(requestParams);
	}

	public async patch(url: string, data: any, query: any) {
		const requestParams: IRequestParams = {
			method: "PATCH",
			url: `${this.baseApiUrl}/${url}`,
			body: data,
			queryParameters: query
		};
		return this.executeRequest(requestParams);
	}

	public async delete(url: string, data: any) {
		const requestParams: IRequestParams = {
			method: "DELETE",
			url: `${this.baseApiUrl}/${url}`,
			body: data
		};
		return this.executeRequest(requestParams);
	}

}
