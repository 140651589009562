import React, {useEffect, useState} from "react"
import Template from "./Template"
import {RootState} from "../model";
import {connect} from "react-redux";
import {useActions} from "../redux/actions";
import * as DentistDaysActions from "../redux/actions/dentistDays";
import * as ScheduleActions from "../redux/actions/schedule";
import {Button, Container, Divider, Dropdown, Grid, Icon, Popup, Table} from "semantic-ui-react";
import {Loading} from "./Loading";
import _, {isArray} from "lodash";
import moment from "moment/moment";
import * as TimeOffRequestsActions from "../redux/actions/timeOffRequests";
import Papa from 'papaparse';

const ProviderDays: React.FC<any> = (props) => {

    const initialState: any = {
        currentMonth: moment().startOf('M').add(1, 'w').toDate(),
        doctorSchedules: [],
        doctorsTimeOff: [],
        doctorShedulesData: [],
        dataDisplayFilter: 'all',
        pdfData: ''
    }

    const { auth } = props
    const { user } = auth
    const [state, setState] = useState(initialState);
    const [loader, setLoader] = useState(false);
    const dentistDaysActions = useActions(DentistDaysActions)
    const scheduleActions = useActions(ScheduleActions)
    const timeOffRequestsActions = useActions(TimeOffRequestsActions);
    const [udaRole, setUdaRole] = useState([2, 10])
    const monthArray = [ -1, 0, 1, 2, 3, 4, 5, 6];
    const dataMonths = Array(monthArray.length + 1).fill(undefined, 1, undefined).map((_, i) => i);

    useEffect(() => {
        fetchData();
    }, []);


    const fetchData = async () => {
        const { currentMonth } = state
        setLoader(true);
        let providerDays = []
        await Promise.all([dentistDaysActions.providerDays()]).then((pd: any) => {
            providerDays = pd[0];
        });
        const udaOptions: any = {}
        udaRole.forEach((val: any) => udaOptions[val] = val)
        const months = monthArray
            .map(i => moment(currentMonth).add(i, 'month'))
        const params = {
            startDate: months[0].startOf('month').format('Y-MM-01'),
            endDate: months[months.length -1].endOf('month').format('Y-MM-DD'),
            udaRole: JSON.stringify({data: udaOptions})
        }
        const obj = {
            startDate : months[0].startOf('month').format('Y-MM-01'),
            endDate : months[months.length -1].endOf('month').format('Y-MM-DD'),
            availableDentists : providerDays.map((value: any) => {
                return value.id
            })
        }

        let result = await timeOffRequestsActions.getAvailableDoctorsTimeOff(obj)
        const doctorsTimeOff = isArray(result) ? result : []

        // Grouping doctorsTimeOff data by userId and by month
       const groupedData = doctorsTimeOff.reduce((acc, obj) => {
            const userId = obj.userId;
            const month = parseInt(obj.startDate.slice(5, 7), 10) // Extracting year and month from startDate [this will be the key]

            // Initializing recordsByMonth if it doesn't exist
            acc[userId] = acc[userId] || { userId, recordsByMonth: {} };

            // Initializing days for the month if it doesn't exist
            acc[userId].recordsByMonth[month] = acc[userId].recordsByMonth[month] || 0;

            // Adding days to the month
            acc[userId].recordsByMonth[month] += obj.days;

            return acc;
        }, {});

        // Convert result object to array of objects
        const approvedTimeOffGroupedByUserAndMonth = Object.values(groupedData)

        setState(prevState => ({...prevState, doctorsTimeOff: approvedTimeOffGroupedByUserAndMonth}));

        const doctorSchedules = await scheduleActions.getDoctorSchedules(params) || [];
        if (isArray(doctorSchedules) && doctorSchedules.length > 0) {
            setState(prevState => ({
                ...prevState,
                doctorSchedules: doctorSchedules,
                doctorSchedulesData: doctorSchedules
            }))
        }
        setLoader(false);
    }

    const {doctorList: data} = props

    const PROVIDER_DAYS_HEADERS = [
        {name: 'Scheduled Days', key: 'scheduled'},
        {name: 'Approved Time Off', key: 'approvedTimeOff'},
        {name: '% RQD Days', key: 'rqdDays'}
    ]
    const {currentMonth} = state;
    const filterOptions = [
        {key: 'all', text: 'All', value: 'all'},
        {key: 'lt100', text: 'Less than 100%', value: 'lt100'},
    ]
    const handleFilterChange = (event, data) => {
        const { doctorSchedulesData, doctorSchedules } = state
        switch (data.value){
            case 'lt100':
                setState(prevState => ({
                    ...prevState,
                    dataDisplayFilter: data.value,
                    doctorSchedules: doctorSchedules
                }))
                break;
            default:
                setState(prevState => ({
                    ...prevState,
                    dataDisplayFilter: data.value,
                    doctorSchedules: doctorSchedulesData
                }))
        }
    };

    const handleExportFiles = async (table) => {
        // Convert the table to CSV
        const csvData = await handleExportToCSV(table)

        const payload = {
            'csv': csvData,
            'emailAddress': user.email,
            'emailSubject': 'Provider Days Table Export'
        }
        dentistDaysActions.sendProviderDaysViaEmail(payload)
    };

    const handleExportToCSV = async (table) => {
        const tableData = Papa.unparse(convertTableToCSV(table));
        return btoa(unescape(encodeURIComponent(tableData)));
    }

    const convertTableToCSV = (table) => {
        // Get all the rows in the table
        const rows = table.querySelectorAll("tr");

        // Create a CSV string
        let csvString = "";

        // Iterate over the rows and add them to the CSV string
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            const cells = row.querySelectorAll("td");
            if (cells.length == 0) {
                // check if we have headers in the row instead of td
                const headersCells = row.querySelectorAll("th");
                // Add the cell values to the CSV string, separated by commas
                for (let i = 0; i < headersCells.length; i++) {
                    const cell = headersCells[i];

                    // If the cell has a colspan attribute, add the value of the colspan attribute to the CSV string
                    if (cell.colSpan > 1) {
                        csvString += cell.textContent + ",";
                        for (let k = 0; k < cell.colSpan - 1; k++) {
                            csvString += ",";
                        }
                    } else {
                        csvString += cell.textContent + ",";
                    }
                }

            }
            // Add the cell values to the CSV string, separated by commas
            for (let j = 0; j < cells.length; j++) {
                csvString += cells[j].textContent + ",";
            }

            // Add a newline character at the end of each row
            csvString += "\n";
        }

        // Parse the CSV string using Papa Parse
        const parsedData = Papa.parse(csvString, {
            headerRows: 2, // Treat the first two rows as headers
            skipEmptyLines: true, // Skip empty lines
        });

        // Extract the data array from the parsed result
        return parsedData.data;
    }

    const handleExport = async () => {
        const dataTable =  document.getElementById("table-provider-days");
        handleExportFiles(dataTable)
    };

    return (
        <Template activeLink="provider-days">
            {loader && <Loading/>}
            <div className="col-12">
                <Grid columns={2} divided>
                    <Grid.Column  textAlign="left" width={12}>
                        <h2>{"Provider Days"}</h2>
                    </Grid.Column>
                    <Grid.Column textAlign="right" width={4} >
                        <Grid>
                            <Grid.Column width={9}>
                                <Dropdown
                                    options={filterOptions}
                                    selection
                                    fluid
                                    style={{zIndex: 901}}
                                    placeholder='All'
                                    onChange={handleFilterChange}
                                />
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <Popup
                                    content={`Send PDF & CSV file to ${user.email}`}
                                    trigger={<Button primary icon labelPosition='right' onClick={handleExport}>
                                        Send
                                        <Icon name='send' />
                                    </Button>}
                                    on="hover"
                                    position="top center"
                                />
                            </Grid.Column>
                        </Grid>


                    </Grid.Column>
                </Grid>

            </div>
            <Divider hidden/>
            <div style={{ overflowX: 'auto', position:'sticky' }}>

            <Container fluid style={{maxWidth: '100px',height: '650px'}}>
                <Table id="table-provider-days" striped={true} compact={true} size="small" celled={true}
                       className="table-responsive">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            {
                                monthArray
                                    .map(i => moment(currentMonth).add(i, 'month'))
                                    .map(month => <Table.HeaderCell style={{borderColor: '#f2711c', borderWidth: '3px'}}
                                                                    colSpan={3} content={<h3
                                        className="text-center">{moment(month).format('MMMM YYYY')}</h3>}/>)
                            }
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell content={"Providers"}/>
                            <Table.HeaderCell content={"Monthly Required Days"}/>
                            {
                                monthArray
                                    .map(i => moment(currentMonth).add(i, 'month'))
                                    .map((date, i) =>
                                        PROVIDER_DAYS_HEADERS.map((header: any, key: number) => <Table.HeaderCell
                                            style={{
                                                borderRightColor: key == PROVIDER_DAYS_HEADERS.length - 1 ? '#f2711c' : '',
                                                borderLeftColor: key == 0 ? '#f2711c' : '',
                                                borderLeftWidth: key == 0 ? '3px' : '',
                                                borderRightWidth: key == PROVIDER_DAYS_HEADERS.length - 1 ? '3px' : ''
                                            }} colSpan={1}>
                                            {header.name}

                                        </Table.HeaderCell>))
                            }
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {

                            _.isArray(data) && data.map((value, index) => {
                                const { dataDisplayFilter } = state
                                let rowData: any
                                let isRecordEligibleForDisplay = false
                                if (isArray(state.doctorSchedules) && state.doctorSchedules.length > 0) {

                                    let doctorSchedule = state.doctorSchedules.find((schedule => schedule.userId == value.id))
                                    let doctorsTimeOff = isArray(state.doctorsTimeOff) ? state.doctorsTimeOff.find(dtf => dtf.userId == value.id) :[];

                                    rowData = <Table.Row key={index}>
                                        <Table.Cell>{`${value?.firstName} ${value?.lastName}`}</Table.Cell>
                                        <Table.Cell>{value?.monthlyRequiredDays}</Table.Cell>
                                        {/* Iterate over each month and render the count */}
                                        {
                                            monthArray
                                                .map(i => moment(currentMonth).add(i, 'month'))
                                                .map(momnt => {
                                                    let month = moment(momnt).format('M')
                                                    let scheduledDays = doctorSchedule?.recordsByMonth[month] || 0
                                                    let approvedTimeOffDays = doctorsTimeOff?.recordsByMonth[month] || 0;
                                                    const totalWorkedDays = scheduledDays + approvedTimeOffDays;
                                                    let percentageRequiredDays = totalWorkedDays / value?.monthlyRequiredDays;
                                                    const isRequiredDaysLessThan100Percent = percentageRequiredDays < 1

                                                    if (dataDisplayFilter === 'all') {
                                                        isRecordEligibleForDisplay = true;
                                                    } else if (dataDisplayFilter === 'lt100' && isRequiredDaysLessThan100Percent) {
                                                        isRecordEligibleForDisplay = true;
                                                    } else {
                                                        isRecordEligibleForDisplay = false;
                                                    }

                                                    return <>
                                                        <Table.Cell
                                                            style={{borderLeftWidth: '3px', borderLeftColor: '#f2711c'}}
                                                        >{scheduledDays}</Table.Cell>
                                                        <Table.Cell>{approvedTimeOffDays}</Table.Cell>
                                                        <Table.Cell style={{
                                                            borderRightColor: '#f2711c',
                                                            borderRightWidth: '3px',
                                                            backgroundColor: isRequiredDaysLessThan100Percent ? '#e4da48' : "",
                                                            fontColor: isRequiredDaysLessThan100Percent ? '#ffffff' : "",
                                                        }}
                                                        >{new Intl.NumberFormat('en-US', {
                                                            style: 'percent',
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0,
                                                        }).format(percentageRequiredDays)}</Table.Cell>
                                                    </>
                                                })}
                                    </Table.Row>

                                } else {
                                    rowData = <Table.Row key={index}>
                                        <Table.Cell>{`${value?.firstName} ${value?.lastName}`}</Table.Cell>
                                        <Table.Cell>{value?.monthlyRequiredDays}</Table.Cell>
                                        {dataMonths.map((month) => (
                                            <>
                                                <Table.Cell>{0}</Table.Cell>
                                                <Table.Cell>{0}</Table.Cell>
                                                <Table.Cell>{0}</Table.Cell>
                                            </>
                                        ))}
                                    </Table.Row>
                                }

                                return isRecordEligibleForDisplay ? (rowData) : ""
                            }) ||
                            <Table.Row>
                                <Table.Cell>No record found.</Table.Cell>
                            </Table.Row>
                        }
                    </Table.Body>

                </Table>
            </Container>
                <Divider hidden/>
            </div>
        </Template>
    )
}

function mapStateToProps(state: RootState) {
    return {
        auth: state.auth,
        reportsTimeOff: state.reports.timeOff,
        practiceList: state.practices.list,
        doctorList: state.dentistDays?.list || [],
        regions: state.practices.regions,
        reportsSchDays: state.reports.schDays,
        loader: state.app.loader,
};
}

export default connect(mapStateToProps)(ProviderDays);
