import * as React from "react";
import { createRoot } from "react-dom/client";
import AppRedux from "./AppRedux";

const container = document.getElementById("root");

if (container) {
    const root = createRoot(container);
    root.render(<AppRedux />);
}
